import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";

import { DirectApplyCreators } from "../../../../../redux/actions";

const FillChoicesCTA = ({
  t,
  application,
  ucasQuickSubmit,
  isUcasChoicesFilled,
  requestUcasChoices,
  isUCASAccountCreated,
  taggingQuickSubmit,
}) => {
  const {
    choices_form_job_status,
    onboarding_show_create_button,
    onboarding_complete,
    jobs_running,
    choices_form_complete,
    choices_form_error_message,
  } = ucasQuickSubmit;

  const handleButtonClick = () => {
    if (!isUcasChoicesFilled) {
      requestUcasChoices(application?.id);
    }
  };

  const renderErrorMessage = () => {
    const isError =
      !choices_form_complete && choices_form_job_status === "Failed";

    return (
      isError && (
        <span className="text-base text-red-800 block text-center">
          {choices_form_error_message ||
            "An error occurred. Please try again. You may also fill this manually or reach out to support."}
        </span>
      )
    );
  };

  const renderContent = () => {
    if (!choices_form_complete) {
      if (
        isUcasChoicesFilled ||
        choices_form_job_status === "Pending" ||
        choices_form_job_status === "Running"
      ) {
        return renderPendingRunningButton();
      }
      if (jobs_running || taggingQuickSubmit?.jobs_running) {
        return renderJobsRunningButton();
      }
      if (onboarding_show_create_button && !onboarding_complete) {
        return renderJobsRunningButton();
      }
      if (
        !application.is_ucas_credential_updated &&
        isUCASAccountCreated
      ) {
        return renderDisableButton();
      }
      if (!application?.in_cialfo_review) {
        return disableAutomation();
      }
      if (choices_form_job_status === "Failed") {
        return renderErrorButton();
      }
      return renderDefaultButton();
    }
    return null;
  };

  const disableAutomation = () => {
    return (
      <>
        <button
          data-tip
          data-for={"fill_choices_automation"}
          disabled={true}
          className={"btn-border-primary mb-4"}
        >
          {t(
            "direct_apply_apps.quick_submit.choices.fill_choices_cta",
          )}
        </button>
        <ReactTooltip
          id={"fill_choices_automation"}
          aria-haspopup="true"
          place="left"
          effect={"solid"}
          arrowColor={"transparent"}
        >
          <p>
            {t(
              "direct_apply_apps.quick_submit.disable_automation_toast",
            )}
          </p>
        </ReactTooltip>
      </>
    );
  };

  const renderPendingRunningButton = () => {
    return (
      <>
        <button
          data-tip
          data-for={"fill_choices_button"}
          disabled={true}
          className={"button-contain-loading-primary-disable mb-4"}
        >
          {t(
            "direct_apply_apps.quick_submit.choices.fill_choices_cta",
          )}
          <Loader
            className={"ml-2 text-gray-500"}
            type="Oval"
            height={10}
            width={10}
          />
        </button>
        <ReactTooltip
          id={"fill_choices_button"}
          aria-haspopup="true"
          place="bottom"
          effect={"solid"}
          arrowColor={"transparent"}
        >
          <p>Fill choices is in progress</p>
        </ReactTooltip>
      </>
    );
  };

  const renderErrorButton = () => {
    return (
      <>
        <button
          onClick={handleButtonClick}
          className={"button-contain-error mb-4"}
        >
          Try again{" "}
          <FontAwesomeIcon icon={"undo"} className={"pl-2"} />
        </button>
        {!jobs_running && renderErrorMessage()}
      </>
    );
  };

  const renderDisableButton = () => {
    return (
      <>
        <button
          data-tip
          data-for={`tooltip-Completes-left`}
          disabled
          className={"btn-border-primary mb-4"}
        >
          {t(
            "direct_apply_apps.quick_submit.choices.fill_choices_cta",
          )}
        </button>
        {!application.is_ucas_credential_updated &&
          isUCASAccountCreated && (
            <ReactTooltip
              id={`tooltip-Completes-left`}
              aria-haspopup="true"
              place="left"
              effect={"solid"}
              arrowColor={"transparent"}
            >
              <p>
                {t("direct_apply_apps.quick_submit.ucas_credential")}
              </p>
            </ReactTooltip>
          )}
      </>
    );
  };

  const renderJobsRunningButton = () => {
    return (
      <button disabled className={"btn-border-primary mb-4"}>
        {t("direct_apply_apps.quick_submit.choices.fill_choices_cta")}
        <FontAwesomeIcon
          icon={["far", "sparkles"]}
          className="pl-2"
        />
      </button>
    );
  };

  const renderDefaultButton = () => {
    return (
      <button
        onClick={handleButtonClick}
        className="button-contain-primary mb-4"
      >
        {t("direct_apply_apps.quick_submit.choices.fill_choices_cta")}
        <FontAwesomeIcon
          icon={["far", "sparkles"]}
          className="pl-2"
        />
      </button>
    );
  };

  return renderContent();
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
  isUcasChoicesFilled: state.directApply.isUcasChoicesFilled,
  taggingQuickSubmit: state.directApply.taggingQuickSubmit,
});

const mapDispatchToProps = {
  requestUcasChoices: DirectApplyCreators.requestUcasChoices,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FillChoicesCTA),
);
